import { useCallback } from 'react';

import type { FilteredView } from '../types';
import exceptionHandler from '../utils/exceptionHandler.platform';
import { globalState } from './models';

const matchesQuery = ({ filteredView, query }: { filteredView: FilteredView; query: string; }) =>
  query === filteredView.query;

export default function useCurrentFilteredView(query: string): FilteredView | undefined {
  return globalState(
    useCallback(
      (state) => {
        if (!state.persistent.filteredViews) {
          return;
        }

        let decodedQuery: string;
        try {
          decodedQuery = decodeURIComponent(query);
        } catch (error) {
          exceptionHandler.captureException(error, {
            extra: {
              query,
            },
          });
          return undefined;
        }
        return Object.values(state.persistent.filteredViews).find((filteredView) =>
          matchesQuery({ filteredView, query: decodedQuery }));
      },
      [query],
    ),
  );
}
export function useCurrentFilteredViewFromId(id: string): FilteredView | undefined {
  return globalState(useCallback((state) => state.persistent.filteredViews[id], [id]));
}
